<style scoped>
.chat-conversation .conversation-list {
	display: block !important;
	margin-bottom: 0.75rem !important;
}
.chat-conversation .right .conversation-list {
	float: unset !important;
}
.chat-conversation .right .conversation-list .ctext-wrap {
	text-align: left !important;
}
</style>

<script>
import appConfig from "@/app.config";

import {mapState, mapGetters} from "vuex";

import simplebar from "simplebar-vue";
import { VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners'

import {get_acsrf_token, with_acrf_token} from "@/methods";
import {TicketPriority, TicketStatus} from "@/enums";
import {maxLength, minLength, required} from "vuelidate/lib/validators";


export default {
  props: ['ticket_id'],
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId',
      'getDTLocale'
    ])
  },
  validations: {
    request: {
      content: {
        required: required,
        maxLength: maxLength(10000)
      }
    }
  },
  components: {
    VclCode,
    simplebar,
    HalfCircleSpinner
  },
  methods: {
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData() {
      let url = new URL(process.env.VUE_APP_ROOT_API + 'v1/support/ticket');
      url.searchParams.append('ticket_id', this.ticket_id);
      let that = this;
      fetch(url, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then((data) => {
          this.ticket = data.ticket;
          this.users = data.users;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        }).then(() => {
          if(this.$refs.chatContainer.scrollElement.offsetHeight >= 400 && this.$refs.chatContainer.scrollElement.scrollHeight > this.$refs.chatContainer.scrollElement.offsetHeight) {
            this.$refs.chatContainer.scrollElement.scrollTo(0, this.$refs.chatContainer.contentElement.offsetHeight);
          }
        });
    },
    closeRequest() {
      let ref = this;
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token,
          ticket_id: ref.ticket_id
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/support/close', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
            .then(response => {
              if(response.ok || response.status === 400){
                return response.json();
              } else {
                if(response.status === 429) {
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('error.server.ratelimit.message'),
                    title: this.$t('error.server.ratelimit.title')
                  });
                } else
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            })
            .then(data => {
              if(!data) return;
              if(data.status) {
                ref.getData();
                this.$swal({
                  icon: 'success',
                  text: this.$t('support.read.closed')
                });
              } else {
                this.$swal({
                  icon: 'error',
                  text: this.$t('support.read.errors.close')
                });
              }
            })
            .catch(error => {
              console.log(`[ERROR] ${error}`);
              this.$swal({
                icon: 'error',
                text: this.$t('support.read.errors.close')
              });
            });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    },
    async answerRequest() {
      if(this.submitted) return;
      this.$v.$touch();
      if(this.$v.request.$error) return;
      this.submitted = true;

	    try {
		    let acsrf_token = await get_acsrf_token();
		    let payload = {
			    acsrf_token: acsrf_token,
			    ticket_id: this.ticket_id,
			    content: this.request.content
		    };
		    let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/support/message`, {
			    method: 'POST',
			    body: JSON.stringify(payload),
			    credentials: 'include'
		    });
		    if (response.ok) {
			    this.request.content = null;
					this.$v.$reset();
			    await this.getData();
		    } else {
			    if (response.status === 429) {
				    this.$swal({
					    icon: 'warning',
					    text: this.$t('error.server.ratelimit.message'),
					    title: this.$t('error.server.ratelimit.title')
				    });
			    } else
				    throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
		    }
	    } catch (error) {
		    console.log(`[ERROR] ${error}`);
		    this.$swal({
			    icon: 'error',
			    text: this.$t('support.read.errors.message')
		    });
	    }
	    this.submitted = false;
    },
    addFile(event) {
      let file = event.target.files[0];
      let actualFileSize = file.size / 1024 / 1024; // MiB
      if (actualFileSize > 10) {
        return this.$toast.error(this.$t('support.request.upload.errors.size', {file: file.name}));
      }
      this.uploading = true;
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token,
          ticket_id: this.ticket_id
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/support/attachment', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              this.uploading = false;
              if (response.status == 429) {
                this.$toast.warning(this.$t('error.server.ratelimit.short'));
              } else
                throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            let upload_url = data.upload_url;
            let upload_token = data.upload_token;

            let resource_id = data.resource_id;

            let payload = new FormData();
            payload.append('file', file);
            payload.append('upload_token', upload_token);

            fetch(upload_url, {
              method: 'POST',
              body: payload,
              credentials: 'include'
            })
              .then(response => {
                if (response.ok) {
                  return response.json();
                } else {
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                }
              })
              .then(data => {
                let resource_url = data.resource.url;

                with_acrf_token().then((r) => {
                  let payload = {
                    acsrf_token: r.acsrf_token,
                    resource_id: resource_id,
                    ticket_id: this.ticket_id,
                    filename: file.name
                  };
                  fetch(process.env.VUE_APP_ROOT_API + 'v1/support/register-attachment', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    credentials: 'include'
                  })
                      .then(response => {
                        if (response.ok || response.status === 400) {
                          return response.json();
                        } else {
                          if (response.status === 429) {
                            this.$toast.warning(this.$t('error.server.ratelimit.short'));

                          } else
                            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                        }
                      })
                      .then(data => {
                        if (data.status) {
                          this.uploading = false;
                          this.ticket.files.push([resource_id, file.name]);
                        } else {
                          this.uploading = false;
                          this.$toast.error(this.$t('error.server.generic.message'));
                        }
                      })
                      .catch(error => {
                        console.log(`[ERROR] ${error}`);
                        this.uploading = false;
                      });
                }).catch(error => {
                  console.log('[ERROR] Failed to request acrf token');
                  this.$toast.error(this.$t('error.server.generic.message'));
                  this.uploading = false;
                });
              })
              .catch(error => {
                console.log(`[ERROR] ${error}`);
                this.$toast.error(this.$t('error.server.generic.message'));
                this.uploading = false;
              });
          })
          .catch(error => {
            console.log(`[ERROR] ${error}`);
            file.error = true;
            file.processing = false;
          });
      });
    },
    filePrompt() {
      document.getElementById('file').click();
    },
    async updateSupportTeamStatus() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/support/info`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.supportTeam = data;
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
  },
  async created() {
    this.supportTeamTimer = setInterval(() => {this.updateSupportTeamStatus();}, 1000 * 30);
  },
  mounted() {
    this.updateSupportTeamStatus();
    this.getData();
  },
  async destroyed() {
    if(this.supportTeamTimer) clearInterval(this.supportTeamTimer);
  },
  data() {
    return {
      TicketPriority: TicketPriority,
      TicketStatus:TicketStatus,
      ready: false,
      error: false,
      ticket: null,
      users: {},
      uploading: false,
      request: {
        content: null
      },
      submitted: false,
      supportTeamTimer: null,
      supportTeam: null,
      businessHours: {
        start: "10:00",
        end: "16:00"
      },
      businessDays: {
        start: "Monday",
        end: "Friday"
      },
    }
  }
};
</script>

<template>
  <div class="row">
    <!-- Display -->
    <template v-if="ready">
      <div class="col-lg-8 col-sm-12">
        <div class="w-100 user-chat">
          <div class="card">
            <div class="p-4 border-bottom">
              <div class="row">
                <div class="col-md-4 col-9">
                  <h5 class="font-size-15 mb-1">{{ $t('support.read.title') }} #{{ ticket.ticket_id_human }}</h5>
                  <p class="text-muted mb-0">
                    <i class="fad fa-clock align-middle mr-1"></i>
                    {{ $d(parseDate(ticket.created_at), 'datetime', getDTLocale()) }}
                  </p>
                </div>
                <div class="col-md-8 col-3">
                  <ul class="list-inline user-chat-nav text-right mb-0">
                    <li class="list-inline-item d-none d-sm-inline-block" v-if="ticket.status !== TicketStatus.CLOSED">
                      <button class="btn btn-rounded btn-danger" v-on:click="closeRequest()">
                        <i class="fas fa-lock-alt"></i>
                        {{ $t('support.read.actions.close') }}
                      </button>
                    </li>
                    <li class="list-inline-item d-none d-sm-inline-block" v-else>
                      <button class="btn btn-rounded btn-dark" disabled="disabled">
                        <i class="fas fa-lock-alt"></i>
                        {{ $t('support.read.status.closed') }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="chat-users">
              <div class="chat-conversation p-3">
                <simplebar
                    style="max-height: 400px"
                    id="chatContainer"
                    ref="chatContainer"
                >
                  <ul class="list-unstyled">
                    <li
                        v-for="message of ticket.correspondence"
                        :key="message.date+message.message"
                        :class="{ right: !(message.is_bot || message.is_staff) }"
                    >
                      <div class="conversation-list">
                        <div class="ctext-wrap">
	                        <!-- Persona -->
                          <template v-if="message.is_bot || message.bot">
                            <template v-if="message.bot">
	                            <div class="conversation-name">
		                            {{ message.bot.name }}
		                            <span>
	                                <i class="fad fa-robot ml-1 font-size-14 text-primary"></i>
	                              </span>
	                            </div>
                            </template>
	                          <template v-else>
		                          <div class="conversation-name">
			                          Evè
			                          <span>
	                                <i class="fad fa-robot ml-1 font-size-14 text-primary"></i>
	                              </span>
		                          </div>
	                          </template>
                          </template>
                          <template v-else-if="message.is_staff || message.agent">
														<template v-if="message.agent">
															<div class="conversation-name">
																{{ message.agent.name }}
																<img src="/logo.svg" width="20" style="margin-left: 0.1rem;">
															</div>
														</template>
                            <template v-else>
	                            <div class="conversation-name">
	                              {{ users[message.user] }}
		                            <img src="/logo.svg" width="20" style="margin-left: 0.1rem;">
	                            </div>
                            </template>
                          </template>

	                        <!-- Message Content -->
                          <template v-if="message.is_bot">
                            <p>
                              {{ $t(message.message) }}
                              <br>
                              <small class="font-size-12">
                                {{ $t('support.read.answers.footer') }}
                              </small>
                            </p>
                          </template>
	                        <template v-else-if="message.bot">
		                        <p class="text-left">
			                        {{ message.message }}
		                        </p>
	                        </template>
                          <template v-else>
	                          <template v-if="message.is_staff || message.agent">
		                          <template v-if="message.agent">
			                          <p v-html="message.message" class="text-left"/>
			                          <p>
				                          {{ message.agent.name }}
				                          <span class="small text-muted">
				                          {{ message.agent.role }}
			                          </span>
			                          </p>
		                          </template>
		                          <template v-else>
			                          <p v-html="message.message" class="text-left"/>
		                          </template>
	                          </template>
	                          <template v-else>
		                          <p v-html="message.message"/>
	                          </template>
                          </template>

	                        <!-- DT stamp -->
                          <p class="chat-time mb-0">
                            <i class="bx bx-time-five align-middle mr-1"></i>
                            {{ $d(parseDate(message.date), 'datetime', getDTLocale()) }}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </simplebar>
              </div>
              <div class="p-3 chat-input-section">
                <div class="row">
                  <div class="col" v-if="ticket.status !== TicketStatus.CLOSED">
                    <div class="position-relative">
                      <textarea
                          id="requestContent"
                          v-model="request.content"
                          rows="5"
                          class="form-control"
                          :placeholder="$t('support.request.content.placeholder')"
                          :class="{ 'is-invalid': $v.request.$error }"
                      />
                      <div v-if="$v.request.$error" class="invalid-feedback">
                        <span v-if="!$v.request.content.required">
                          {{ $t('support.request.content.errors.required') }}
                        </span>
                        <span v-if="!$v.request.content.maxLength">
                          {{ $t('support.request.content.errors.maxLength') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col" v-if="ticket.status !== TicketStatus.CLOSED">
                    <button class="btn btn-block btn-primary" v-on:click="answerRequest()" :disabled="submitted">
                      <h4 class="m-0">
                        <template v-if="submitted">
                          <half-circle-spinner v-if="submitted"
                           :animation-duration="1200"
                           :size="24"
                           class="align-middle d-inline-block"
                          />
                        </template>
                        <template v-else>
                          {{ $t('support.read.actions.send') }}
                        </template>
                      </h4>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.created')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                {{ $d(parseDate(ticket.created_at), 'datetime', getDTLocale()) }}
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.updated')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                {{ $d(parseDate(ticket.updated_at), 'datetime', getDTLocale()) }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.reference')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                {{ ticket.reference }}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.status.title')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                <template v-if="ticket.status === TicketStatus.CLOSED">
                  <span class="badge badge-light text-dark">
                    <b>{{$t('support.read.status.status.closed')}}</b>
                  </span>
                </template>
                <template v-else-if="ticket.status === TicketStatus.WAITING_FOR_USER">
                  <span class="badge badge-success text-light">
                    <b>{{$t('support.read.status.status.user')}}</b>
                  </span>
                </template>
                <template v-else-if="ticket.status === TicketStatus.WAITING_FOR_SUPPORT">
                  <span class="badge badge-info">
                    <b>{{$t('support.read.status.status.support')}}</b>
                  </span>
                </template>
                <template v-else-if="ticket.status === TicketStatus.WAITING_FOR_TRIAGE">
                  <span class="badge badge-warning text-light">
                    <b>{{$t('support.read.status.status.triage')}}</b>
                  </span>
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <h6 class="m-0 text-uppercase">
                  {{$t('support.read.status.priority.title')}}
                </h6>
              </div>
              <div class="col-lg-9 col-sm-12 text-white">
                <template v-if="ticket.priority === TicketPriority.NONE">
                  <i>{{$t('support.read.status.priority.none')}}</i>
                </template>
                <template v-else-if="ticket.priority === TicketPriority.LOW">
                  <b>{{$t('support.read.status.priority.low')}}</b>
                </template>
                <template v-else-if="ticket.priority === TicketPriority.MEDIUM">
                  <b>{{$t('support.read.status.priority.medium')}}</b>
                </template>
                <template v-else-if="ticket.priority === TicketPriority.HIGH">
                  <b>{{$t('support.read.status.priority.high')}}</b>
                </template>
                <template v-else-if="ticket.priority === TicketPriority.CRITICAL">
                  <b>{{$t('support.read.status.priority.critical')}}</b>
                </template>
              </div>
            </div>
            <hr>
            <h6 class="m-0 text-uppercase">
              {{$t('support.read.status.files')}}
              <template v-if="ticket.files.length < 10 && ticket.status !== TicketStatus.CLOSED">
                <button class="ml-2 btn btn-rounded btn-primary btn-sm" :disabled="uploading ? true : false" v-on:click="filePrompt()">
                  <i class="fad fa-cloud-upload"></i>
                  {{$t('support.read.actions.upload')}}
                </button>
                <span class="ml-2 small text-muted" v-if="uploading">
                  <i class="fas fa-spinner fa-spin"></i>
                  Uploading...
                </span>
                <input type="file" v-on:change="addFile($event)" id="file" style="display: none;" ref="file">
              </template>
            </h6>
            <ul class="ml-0 pl-0 mt-2" style="list-style: none;">
              <li v-for="file in ticket.files" :key="file[0]+Math.random()">
                <i class="fad fa-file-alt align-middle"></i>
                <a :href="'https://usrcdn.cftools.cloud/s1/'+file[0]+'/'+file[1]" target="_blank">
                  {{file[1]}}
                </a>
                <a :href="'https://usrcdn.cftools.cloud/s1/'+file[0]+'/'+file[1]" target="_blank">
                  <span class="ml-1 small text-muted">
                    {{$t('support.read.actions.download')}}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <template v-if="supportTeam">
          <div v-if="supportTeam.holiday" class="row">
            <div class="col-lg-12 mt-n3">
              <div class="card text-black bg-warning">
                <div class="card-body p-2">
                  <h5 class="mt-0 mb-2 text-black font-size-20">
                    <i class="fad fa-info-circle mr-1"></i> {{ $t('support.disclaimer.holiday.title') }}
                  </h5>
                  <p class="card-text text-black">
                    <u>{{ $t('support.disclaimer.holiday.message') }}</u>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="!supportTeam.available" class="row">
            <div class="col-lg-12 mt-n3">
              <div class="card text-black bg-warning">
                <div class="card-body p-2">
                  <h5 class="mt-0 mb-2 text-black font-size-20">
                    <i class="fad fa-info-circle mr-1"></i> {{ $t('support.disclaimer.officehours.title') }}
                  </h5>
                  <div class="card-text text-black">
                  <span>
                    {{ $t('support.disclaimer.officehours.message1') }}
                  </span>
                    <hr class="mt-2 mb-2 p-0"/>
                    <div class="text-center">
                      {{ $t('support.disclaimer.officehours.info') }}<br>
                      <b class="ml-1">{{ businessHours.start }} - {{ businessHours.end }} / {{ businessDays.start }} - {{ businessDays.end }}</b><br>
                      <small>(Europe/Berlin timezone)</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <!-- Error -->
    <template v-else-if="error">
      <div class="col-lg-12 col-sm-12 row justify-content-center">
      <div class="col-lg-6 col-sm-12">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i>
              {{$t('error.server.generic.title')}}
            </h5>
          </div>
          <div class="card-body pt-0">
            <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
          </div>
        </div>
      </div>
      </div>
    </template>
    <!-- Loading -->
    <template v-else>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <h5 class="text-uppercase">{{$t('support.read.title')}} #{{ticket_id}}</h5>
            <vcl-code :height="90"></vcl-code>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <vcl-code :height="90"></vcl-code>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>